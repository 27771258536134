const scrollingSponser = [
  {
    id: 1,
    imageLink:
      "https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-2.png",
  },
  {
    id: 2,
    imageLink:
      "https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-4.png",
  },
  {
    id: 3,
    imageLink:
      "https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-6.png",
  },
  {
    id: 4,
    imageLink:
      "https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-7.png",
  },
  {
    id: 5,
    imageLink:
      "https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-1.png",
  },
  {
    id: 6,
    imageLink:
      "https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-8.png",
  },
  {
    id: 7,
    imageLink:
      "https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-3.png",
  },
  {
    id: 8,
    imageLink:
      "https://bootstrapmade.com/demo/templates/Arsha/assets/img/clients/client-5.png",
  },
];

export default scrollingSponser;
