const navList = [
  {
    id: 1,
    linkName: "Home",
    linkAddress: "home",
  },
  {
    id: 2,
    linkName: "About",
    linkAddress: "about",
  },
  {
    id: 3,
    linkName: "Services",
    linkAddress: "services",
  },
  {
    id: 4,
    linkName: "Portfolio",
    linkAddress: "portfolio",
  },
  {
    id: 5,
    linkName: "Team",
    linkAddress: "team",
  },
  {
    id: 6,
    linkName: "Contact",
    linkAddress: "contact",
  },
];

export default navList;
